import React from 'react'
import axios from 'axios';
import {Fragment} from 'react'

export default class Select extends React.Component 
{
   constructor()
   {
       super()
	   this.state=
	   {
			myData:[],
			CountryName: null,
            Capital:null,
            Region:null,
			Currencies:null,
			CallingCode:null,
			Population:null,
            Flag:null,
            isLoaded:false
       }
   }

	async componentDidMount()
	{
			axios.get(`https://restcountries.eu/rest/v2/all`)
			.then(res=>{
				this.setState
				({
					myData:res.data,
					
				})
				//console.log(res)
			})

			.catch((err) => {

				this.setState({myData:"Bad"})
				//console.log(err)
			})   
	}

	search = async(event) =>
	{
		event.preventDefault()
		const countryName = this.refs.country.value
        const countryApi = `https://restcountries.eu/rest/v2/name/${countryName}?fullText=true`;
		axios.get(
			countryApi,
		).then(res =>{
			this.setState
            ({
                CountryName: res.data[0].name,
                Capital:"Capital:"+res.data[0].capital,
                Region:"Region:"+res.data[0].region,
				Currencies:"Currency:"+res.data[0].currencies[0].code+"(Symbol: "+res.data[0].currencies[0].symbol+")",
				CallingCode:"Calling code: "+res.data[0].callingCodes,
				Population:"Population:"+res.data[0].population,
                Flag:res.data[0].flag,
                isLoaded:true
            })
			console.log(res)
		}).catch(err =>{
			console.log(err)
		})
	}

	render() 
	{
        const CountryList = this.state.myData.map((data)=>
        {
          	return <option  value={data.name}>{data.name}</option>
		})
		
        return (
			<div  className="content">
				<p className="big1">I-Globe</p>
				<select ref="country" className="select-box" onChange={this.search}>
					{CountryList}
				</select>
				<Fragment>
					<p className="big">{this.state.CountryName }</p>
					<p className="medium">{this.state.Capital }</p>
					<p className="small">{this.state.Region }</p>
					<p className="small">{this.state.CallingCode}</p>
					<p className="small">{this.state.Population }</p>
					<p className="small">{this.state.Currencies }</p>
					<img src={this.state.CountryName? this.state.Flag : ''} alt="" width="80px" height="50px" />
				</Fragment>

            </div>
        )
    }
}

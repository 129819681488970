import React from 'react'
import axios from 'axios'

export default class Home extends React.Component {
    constructor() {
        super()
        this.state = {
            CountryName: null,
            Capital:null,
            Region:null,
            Currencies:null,
            Flag:null,
            Population:null,
            CallingCode:null,
            isLoaded:false
           
        }
    }

    getCountry = async(e) => 
    {
        
        e.preventDefault()
        const country = e.target.elements.country.value;
        const countryApi = `https://restcountries.eu/rest/v2/name/${country}?fullText=true`;
        axios.get(countryApi)
         
         .then(res=>{
             this.setState
            ({
                CountryName: res.data[0].name,
                Capital:"Capital:"+res.data[0].capital,
                Region:"Region:"+res.data[0].region,
                Currencies:"Currency:"+res.data[0].currencies[0].code+"(Symbol: "+res.data[0].currencies[0].symbol+")",
                Population:"Population:"+res.data[0].population,
                CallingCode:"Calling code: "+res.data[0].callingCodes,
                Flag:res.data[0].flag,
                isLoaded:true
            })
             
             
             
                console.log(res.data)
        })

         .catch(err =>{
            this.setState
            ({
                CountryName: "Country name not Found",
                Capital:null,
                Region:null,
                Currencies:null,
                Population:null,
                CallingCode:null,
                Flag:"",
                isLoaded:true
            })
           
           
            console.log(err.data)
        })
    }
    
    render() {
        return (
            <div  className="content">
                <p className="big1">I-Globe</p>
                <div className="search-content">
                    <form onSubmit={this.getCountry} className="search-box-wrapper">
                        <input type="search" name="country" className="search-box-input" placeholder="Search Your Country Details"></input>
                        <button className="search-box-button" name="submit">&#128269;</button>
                        
                    </form>
                </div>

                <div style={{display: this.state.isLoaded? "block":"none"}}>
                    <p className="big">{this.state.CountryName }</p>
                    <p className="medium">{this.state.Capital }</p>
                    <p className="small">{this.state.Region }</p>
                    <p className="small">{this.state.Population }</p>
                    <p className="small">{this.state.CallingCode}</p>
                    <p className="small">{this.state.Currencies }</p>
                    <img src={this.state.Flag} alt="" width="80px" height="50px" />
                </div>
            </div>
        )
    }
}

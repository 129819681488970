import React from 'react';
import { BrowserRouter,Route } from 'react-router-dom';

import './App.css';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Select from './components/Select';

function App() {
  return (
     <BrowserRouter>
      <div className="App">
            <NavBar />
              <Route exact path = "/" component={Home} />
              <Route path="/select" component={Select} />
            
      </div>
     </BrowserRouter>
  );
}

export default App;
